import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const Gdpr = loadable(() => import("@components/GDPR/Gdpr"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const GdprPage = ({ path, data }) => (
    <>
        <SEO
            title="Impressit GDPR Compliance"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Impressit is a GDPR-friendly company. You have the right to determine what you allow us to collect. Please check this section for more details."
            path={path}
        />
        <Layout path={path}>
            <Gdpr />
        </Layout>
    </>
);

export default GdprPage;

export const gdprPageQuery = graphql`
    query gdprPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
